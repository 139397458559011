@media (max-width: 900px) {
  .noneLogin {
    display: none;
  }

  .contained {
    margin-top: 20px !important;
    margin-bottom: 50px !important;
  }

  .containedB {
    margin-top: 20px !important;
  }
}

/*  */
.container-app {
  /* background: linear-gradient(120deg, rgba(42, 40, 39, 1) 0%, rgba(255, 109, 0, 1) 100%); */
  background: linear-gradient(45deg, rgba(118, 115, 114) 0%, rgba(240, 139, 61) 100%);
}

/* link normal */
.link_normal {
  text-decoration: none;
}


.textDrawer {
  color: #f57c00;
}

.textDrawer2 {
  color: red !important;
}

.textDrawer:hover ListItemIcon {
  text-decoration: none;
  color: #fff;
}

.sidebar_active {
  background-color: #f57c00 !important;
  color: red !important;
  /* border-left: 8px solid #fff !important; */
}

.sidebar_active_barra {
  /* background-color: #8AA4AF !important; */
  border-left: 6px solid #f57c00 !important;
}

.sidebar_active_barra2 {
  /* background-color: #8AA4AF !important; */
  color: #f57c00;
  border-left: 1px solid #f57c00 !important;
}

.sidebar_active_icon {
  color: #424242 !important;
}

.button_search {
  margin-top: 15px !important;
  color: #888888 !important;
  width: 30px;
  height: 50px;
}

.addButton {
  border: 1px solid #ffb90f !important;
  border-radius: 40px !important;
  color: #ffb90f !important;
  font-size: 15px !important;
  background: #fff !important;
}

.desplazamiento {
  margin-left: 240px;
}

.desplazamientoC {
  margin-left: 60px;
}

.displayNone {
  display: none;
}

.hoverCls {
  cursor: pointer;
}

/* card */
.imgCard {
  height: 200px !important;
  background: url("https://infopodologos.com/wp-content/uploads/AF1QipNvqPeUxDrYcdYRzUA_1ZeGrV1wrnnl-6tTZQdvw408-h270-k-no.jpg");
  background-size: cover;
  top: 50px;
}

.none {
  display: none;
}

.text-odon {
  fill: #fff;
  font-weight: bold;
  cursor: pointer;
}

/* navbar */
.navItem {
  background: #424242;
}

.scroll::-webkit-scrollbar {
  width: 4px !important;
  background: red;
}

.error404 {
  font-size: 9em !important;
  font-family: 'Arvo', serif !important;
  /* color: #d32f2f; */
  font-weight: 700;
  /* font-size: 5em !important; */
}

.btn404 {
  font-family: 'Arvo', serif !important;
}

/* 
@media (max-width: 500px) {
  .MuiDrawer-paper {
    width: 0 !important;
  }
} */

/* ============CARD HOME============ */
.wrap {
  width: 225px;
  justify-content: center;
}

.tarjeta-wrap {
  -webkit-perspective: 800;
  perspective: 800;
}

.tarjeta {
  width: 215px;
  height: 225px;
  background: #424242;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: .7s ease;
  transition: .7s ease;
  -webkit-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.65);
  box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.65);
  border-radius: 14px;
  color: #f57c00 !important;
}

.adelante,
.atras {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 14px;
}

.adelante {
  width: 100%;
}

.atras2 {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  color: #f57c00 !important;
  font-family: "open sans";
  background: #2b2b;
}

.atras{

  -webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);

	padding: 15px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;

	text-align: center;
	color: #2b2b;
	font-family: "open sans";
  background: #F29720;
}

.tarjeta-wrap:hover .tarjeta {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.cardWrap-Borrar {
  background-image: url(https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2022/05/ia-google.jpg?fit=1500%2C1500&quality=50&strip=all&ssl=1);
  background-size: cover;
}


 /* =======tarjeta wrap v3======= */
 .flip {
  position: relative;
  }
.flip > .front, .flip > .back {
  display: block;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 1.2s;
  transition-property: transform, opacity;
}
.flip > .front {
  transform: rotateY(0deg);
}
.flip > .back {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;  
  transform: rotateY(-180deg);
  background-color: "#2B2B";
}
.flip:hover > .front {
  transform: rotateY(180deg);
}
.flip:hover > .back {
  opacity: 1;
  transform: rotateY(0deg);
}
.flip.flip-vertical > .back {
  transform: rotateX(-180deg);
}
.flip.flip-vertical:hover > .front {
  transform: rotateX(180deg);
}
.flip.flip-vertical:hover > .back {
  transform: rotateX(0deg);
}
.flip {
  position: relative;
  display: inline-block;
  
  /* margin-right: 2px;
  margin-bottom: 1em;
  width: 400px; */
}
.flip > .front, .flip > .back {
  display: block;
  color: white;
  width: inherit;
  background-size: cover !important;
  background-position: center !important;
  height: 210px;
  width: 160px;
  padding: 1em 2em;
  background: #424242;
  border-radius: 15px;
    
}
.flip > .front p, .flip > .back p {
  font-size: 0.9rem;
  /* line-height: 190%; */
  color: #f57c00;
}
.text-shadow {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.04), 2px 2px rgba(0, 0, 0, 0.04), 3px 3px rgba(0, 0, 0, 0.04), 4px 4px rgba(0, 0, 0, 0.04), 0.125rem 0.125rem rgba(0, 0, 0, 0.04), 6px 6px rgba(0, 0, 0, 0.04), 7px 7px rgba(0, 0, 0, 0.04), 8px 8px rgba(0, 0, 0, 0.04), 9px 9px rgba(0, 0, 0, 0.04), 0.3125rem 0.3125rem rgba(0, 0, 0, 0.04), 11px 11px rgba(0, 0, 0, 0.04), 12px 12px rgba(0, 0, 0, 0.04), 13px 13px rgba(0, 0, 0, 0.04), 14px 14px rgba(0, 0, 0, 0.04), 0.625rem 0.625rem rgba(0, 0, 0, 0.04), 16px 16px rgba(0, 0, 0, 0.04), 17px 17px rgba(0, 0, 0, 0.04), 18px 18px rgba(0, 0, 0, 0.04), 19px 19px rgba(0, 0, 0, 0.04), 1.25rem 1.25rem rgba(0, 0, 0, 0.04);
}

/* ==============fin tarjeta =============*/


.btnSalir {
  position: fixed;
  bottom: 25px;
  right: 20px;
}

.btnCerrarSesion:hover {

  background-color: #f57c00 !important;
  color: #424242 !important;
  box-shadow: 0px 10px 15px -6px rgba(0, 0, 0, 0.65) !important;
  border-radius: 10px !important;
  transition: all 300ms ease !important;
  width: 210px !important;
}

.btnCerrarSesion:hover::before {
  content: "Cerrar sesión";
}

.btn404 {
  background: #1976d2;
  color: #fff
}

.btn404Outlined {
  border: 1px solid #1976d2;
  color: #1976d2
}

.contenedor_odontograma {
  position: relative !important;
  width: 100%;
  height: 550px;
}

.view_odontograma {
  position: relative !important;
  width: 100%;
  height: 590px;
}

.contenedor_odontograma .tooth {
  position: absolute !important;
  /* margin: auto; */
}

.view_odontograma .tooth {
  position: absolute !important;
  /* margin: auto; */
}